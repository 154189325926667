import styles from './Pagination.module.scss';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import PropTypes from 'prop-types';
import React from 'react';

const Pagination = ({
  lastPage,
  currentPage,
  setCurrentPage,
  isStatic = false,
}) => {
  const pageArrLength = lastPage ? lastPage + 1 : 0;
  const totalPages = Array.from(new Array(pageArrLength).keys());

  const pageIndex = currentPage <= 1 ? 1 : currentPage;
  const totalPagesMoved = totalPages.slice(pageIndex - 1);
  const pages = totalPagesMoved.slice(0, 3);
  const undisruptedPages = totalPages.slice(1, totalPages.length);

  const showFirstPage = currentPage < 3 || currentPage + 1 >= lastPage;
  const showLastPage = currentPage + 1 >= lastPage || showFirstPage;

  const canMoveNext = !(currentPage >= lastPage);
  const canMovePrevious = !(currentPage <= 1);

  const firstThreePages = [1, 2, 3];
  const lastThreePages = totalPages.reverse().slice(0, 3).reverse();

  const handleGoToPage = pageNumber => {
    if (isStatic) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setCurrentPage(pageNumber);
  };

  const renderRoundPageNumber = pageNumber => {
    return (
      <span key={pageNumber} className={styles.buttonWrapper}>
        <Button
          shape="bubble"
          size="small"
          theme={pageNumber === currentPage ? 'contrast' : 'link'}
          className={styles.goToPageButton}
          onClick={() => handleGoToPage(pageNumber)}
        >
          {pageNumber}
        </Button>
      </span>
    );
  };

  const renderSlicedPagination = () => {
    return (
      <>
        {showFirstPage &&
          firstThreePages.map(pageNumber => {
            return renderRoundPageNumber(pageNumber);
          })}
        {!showFirstPage && !showLastPage && renderRoundPageNumber(1)}
        {!showFirstPage && !showLastPage && (
          <div className={styles.threeDotsWrapper}>
            <span className={styles.threeDots}>.</span>
            <span className={styles.threeDots}>.</span>
            <span className={styles.threeDots}>.</span>
          </div>
        )}
        {!showFirstPage &&
          !showLastPage &&
          pages.map(pageNumber => {
            return renderRoundPageNumber(pageNumber);
          })}
        {
          <div className={styles.threeDotsWrapper}>
            <span className={styles.threeDots}>.</span>
            <span className={styles.threeDots}>.</span>
            <span className={styles.threeDots}>.</span>
          </div>
        }
        {!showFirstPage && !showLastPage && renderRoundPageNumber(lastPage)}
        {showLastPage &&
          lastThreePages.map(pageNumber => {
            return renderRoundPageNumber(pageNumber);
          })}
      </>
    );
  };

  const renderUndisruptedPagination = () => {
    return (
      <>
        {undisruptedPages.map(pageNumber => {
          return renderRoundPageNumber(pageNumber);
        })}
      </>
    );
  };

  if (lastPage < 2) {
    return null;
  }

  return (
    <div>
      {lastPage && (
        <div className={styles.paginationWrapper}>
          {canMovePrevious && (
            <Button
              theme="secondary"
              size="small"
              className={styles.moveNextOrPreviousButton}
              onClick={() => handleGoToPage(currentPage - 1)}
            >
              <span className={styles.smallArrowLongLeftIcon}>
                <IconWrapper Icon={IconComponents.SmallArrowLongLeftIcon} />
              </span>
              Previous
            </Button>
          )}
          {lastPage < 6
            ? renderUndisruptedPagination()
            : renderSlicedPagination()}
          {canMoveNext && (
            <Button
              theme="secondary"
              size="small"
              className={styles.moveNextOrPreviousButton}
              onClick={() => handleGoToPage(currentPage + 1)}
            >
              Next
              <span className={styles.smallArrowLongRightIcon}>
                <IconWrapper Icon={IconComponents.SmallArrowLongRightIcon} />
              </span>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  lastPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default Pagination;
