import styles from './allTrials.module.scss';
import CriteriaRow from './components/CriteriaRow';
import Layout from 'components/Layout/Layout';
import Client from 'api/client';
import DataTable from 'components/DataTable/DataTable';
import Container from 'components/Container/Container';
import { navigate } from 'gatsby';
import Pill from '@moonshineragency/ui/src/components/Pill/Pill';
import React, { useState } from 'react';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import classNames from 'classnames';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import {
  unstable_useFormState as useFormState,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_Form as Form,
} from 'reakit/Form';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Button from '@moonshineragency/ui/src/components/Button/Button';

const columns = [
  {
    Header: 'NCTID',
    accessor: 'nct_id',
    columnPrefix: 'trial',
  },
  {
    Header: 'Brief title',
    accessor: 'brief_title',
    columnPrefix: 'trial',
  },
  {
    Header: 'Sponsor',
    accessor: 'organization',
    columnPrefix: 'rawTrial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Design',
    accessor: 'intervention_model',
    columnPrefix: 'trial',
  },
  {
    Header: 'Criteria',
    accessor: 'criteria',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Status',
    accessor: 'overall_recruitment_status',
    columnPrefix: 'rawTrial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Target enrollment',
    accessor: 'enrollment_count',
    columnPrefix: 'rawTrial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Study phase & design',
    accessor: 'phase',
    columnPrefix: 'rawTrial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Primary intervention type',
    accessor: 'primary_intervention.name',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Primary potential benefit',
    accessor: 'primary_benefit.name',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Outcome measure',
    accessor: 'outcome_measures',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Follow up',
    accessor: 'final_testing_performed',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Start date',
    accessor: 'start_date',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'First posted',
    accessor: 'first_posted_date',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Location',
    accessor: 'number_of_locations',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
];

const AllTrialsTemplate = () => {
  const handleTableRowClick = row => {
    navigate(`/trials/${row.original.nct_id}`);
  };
  const [searchData, setSearchData] = useState(null);
  const [metaData, setMetaData] = useState({});

  const popoverId = usePopoverState();
  const popoverTitle = usePopoverState();

  const form = useFormState({
    values: {
      search_id: '',
      search_title: '',
      page: 1,
    },
    onSubmit: async (values, errors) => {
      try {
        const response = await Client.scientificTrialSearch.get(
          values.search_id,
          values.search_title,
          values.page,
        );
        setSearchData(response.data);
      } catch (e) {
        throw errors;
      }

      popoverId.hide();
      popoverTitle.hide();
    },
  });

  const isSearchIdGiven = !!form?.values?.search_id;
  const isSearchTitleGiven = !!form?.values?.search_title;

  const handleResetSearch = formField => {
    if (formField === 'search_id') {
      form.values.search_id = '';
    }

    if (formField === 'search_title') {
      form.values.search_title = '';
    }

    form.submit();

    if (popoverId.visible) {
      popoverId.hide();
    }

    if (popoverTitle.visible) {
      popoverTitle.hide();
    }
  };

  const renderSearchIdPopover = column => {
    return (
      <div className={styles.popOverHeaderWrapper}>
        {column.Header}
        <PopoverDisclosure className={styles.popoverDisclosure} {...popoverId}>
          <IconWrapper
            className={{
              [styles.ispopoverOpen]: popoverId.visible,
            }}
            Icon={IconComponents.SearchIconSmall}
          />
        </PopoverDisclosure>
        <Popover className={styles.popover} {...popoverId}>
          <div className={styles.popoverWrapper}>
            <Form {...form}>
              <div>
                <InputField
                  name="search_id"
                  icon={IconComponents.SearchIconSmall}
                  {...form}
                  placeholder="Search..."
                />
              </div>
              <div
                className={classNames(styles.searchPopOverActions, {
                  [styles.applySearchButton]: !isSearchIdGiven,
                })}
              >
                {isSearchIdGiven && (
                  <Button
                    theme="link"
                    onClick={() => handleResetSearch('search_id')}
                  >
                    Reset
                  </Button>
                )}
                <FormSubmitButton
                  as={Button}
                  theme="secondary"
                  size="default"
                  {...form}
                >
                  Apply
                </FormSubmitButton>
              </div>
            </Form>
          </div>
        </Popover>
      </div>
    );
  };

  const renderSearchTitlePopover = column => {
    return (
      <div className={styles.popOverHeaderWrapper}>
        {column.Header}
        <PopoverDisclosure
          className={styles.popoverDisclosure}
          {...popoverTitle}
        >
          <IconWrapper
            className={{
              [styles.ispopoverOpen]: popoverId.visible,
            }}
            Icon={IconComponents.SearchIconSmall}
          />
        </PopoverDisclosure>
        <Popover className={styles.popover} {...popoverTitle}>
          <div className={styles.popoverWrapper}>
            <Form {...form}>
              <div>
                <InputField
                  name="search_title"
                  {...form}
                  placeholder="Search..."
                  icon={IconComponents.SearchIconSmall}
                />
              </div>
              <div
                className={classNames(styles.searchPopOverActions, {
                  [styles.applySearchButton]: !isSearchTitleGiven,
                })}
              >
                {isSearchTitleGiven && (
                  <Button
                    theme="link"
                    onClick={() => handleResetSearch('search_title')}
                  >
                    Reset
                  </Button>
                )}
                <FormSubmitButton
                  as={Button}
                  theme="secondary"
                  size="default"
                  {...form}
                >
                  Apply
                </FormSubmitButton>
              </div>
            </Form>
          </div>
        </Popover>
      </div>
    );
  };

  const headerFormatter = column => {
    if (column.id === 'nct_id') {
      return renderSearchIdPopover(column);
    }

    if (column.id === 'brief_title') {
      return renderSearchTitlePopover(column);
    }

    return column.render('Header');
  };

  const convertStatus = status =>
    ({
      recruiting: 'Recruiting',
      'recruiting-soon': 'Recruiting soon',
      'not-recruiting': 'Not recruiting',
      ended: 'Ended',
      active: 'Active',
      'by-invitation': 'By invitation',
    }[status]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formatDate = date => {
    if (date === null) {
      return 'Never';
    }

    const dateObject = new Date(date);
    const dateDay = dateObject.getDate();
    const formattedDateDay = dateDay > 9 ? dateDay : `0${dateDay}`;
    const dateMonth = months[dateObject.getMonth()];
    const dateYear = dateObject.getFullYear();

    return `${formattedDateDay} ${dateMonth} ${dateYear}`;
  };

  const rowFormatter = cell => {
    const dateColumns = ['start_date', 'first_posted_date'];
    if (dateColumns.includes(cell.column.id)) {
      const formattedDate = formatDate(cell.value);

      return formattedDate;
    }

    if (cell.column.id === 'criteria') {
      return <CriteriaRow row={cell} />;
    }

    if (cell.column.id === 'overall_recruitment_status') {
      return convertStatus(cell.row.original?.overall_recruitment_status) || '';
    }

    if (cell.column.id === 'outcome_measures') {
      return (
        <ul>
          {cell?.row?.original?.outcome_measures?.map(measure => (
            <li key={measure.name}>{measure.name}</li>
          ))}
        </ul>
      );
    }

    if (typeof cell.value === 'object' && cell.value !== null) {
      return cell.value.name;
    }

    return cell.render('Cell');
  };

  const dataFetcher = async (page = 1) => {
    const response = await Client.scientificTrialSearch.get(
      form.values.search_id,
      form.values.search_title,
      page,
    );
    return response.data;
  };

  return (
    <Layout
      title="All spinal cord injury trials"
      desription="Brief overview of all clinical trials on SCI Trials Finder. You can also download your selected trials."
      hideSearch
    >
      <Container size="wide">
        <Heading className={styles.heading} size="h1" theme="primary">
          All trials
        </Heading>

        <div className={styles.infoBar}>
          <div className={styles.searchPillsWrapper}>
            <span>
              Displaying {metaData?.to} of {metaData?.total} results{' '}
              {(isSearchIdGiven || isSearchTitleGiven) && 'filtered by'}
            </span>
            <div className={styles.searchedByItems}>
              {isSearchIdGiven && (
                <Pill onClick={() => handleResetSearch('search_id')}>
                  {form.values.search_id}
                </Pill>
              )}
              {isSearchTitleGiven && (
                <Pill onClick={() => handleResetSearch('search_title')}>
                  {form.values.search_title}
                </Pill>
              )}
            </div>
          </div>
          <Link
            target="_blank"
            variant="noUnderline"
            size="100"
            type="ui"
            href={`/api/scientific-trial?format=csv&id=${form.values.search_id}&title=${form.values.search_title}`}
            theme="link"
            className={styles.link}
          >
            <IconWrapper
              Icon={IconComponents.SmallDownload}
              alt="printer"
              className={styles.linkIcon}
              as="internal"
            />
            Download as CSV
          </Link>
        </div>

        <DataTable
          onTableRowClick={row => handleTableRowClick(row)}
          dataFetcher={dataFetcher}
          headerFormatter={headerFormatter}
          rowFormatter={rowFormatter}
          columns={columns}
          form={form}
          searchData={searchData}
          setMetaData={setMetaData}
          noSorting
        />
      </Container>

      <br />
    </Layout>
  );
};

export default AllTrialsTemplate;
