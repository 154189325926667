import styles from './CriteriaRow.module.scss';
import React from 'react';

const CriteriaRow = ({ row }) => {
  const criteria = row?.row?.original?.criteria;

  const formatAis = () => {
    const aisArray = [];
    if (criteria.ais_a) {
      aisArray.push('A');
    }
    if (criteria.ais_b) {
      aisArray.push('B');
    }
    if (criteria.ais_c) {
      aisArray.push('C');
    }
    if (criteria.ais_d) {
      aisArray.push('D');
    }
    if (criteria.ais_e) {
      aisArray.push('E');
    }
    return aisArray.join(', ');
  };

  const renderAge = () => {
    if (criteria?.age_to === null && criteria?.age_from === null) {
      return `All ages`;
    }
    if (criteria?.age_to === null || criteria?.age_to >= 100) {
      return `≥ ${criteria?.age_from} yrs`;
    }
    if (criteria?.age_from === null) {
      return `≤ ${criteria?.age_to} yrs`;
    }
    return `${criteria?.age_from} - ${criteria?.age_to} yrs`;
  };

  const renderInjuryTime = () => {
    if (
      criteria.time_since_injury_to === null &&
      criteria.time_since_injury_from === null
    ) {
      return `All`;
    }
    if (
      criteria.time_since_injury_to === null ||
      criteria.time_since_injury_to >= 100
    ) {
      return `≥ ${criteria.time_since_injury_from} ${criteria.time_since_injury_from_unit}`;
    }
    if (
      criteria.time_since_injury_from === null ||
      criteria.time_since_injury_from === 0
    ) {
      return `≤ ${criteria.time_since_injury_to} ${criteria.time_since_injury_to_unit}`;
    }
    return `${criteria.time_since_injury_from} ${criteria.time_since_injury_from_unit} - ${criteria.time_since_injury_to} ${criteria.time_since_injury_to_unit}`;
  };

  return (
    <div className={styles.criteriaContainer}>
      <ul className={styles.criteriaList}>
        <li key={`age${criteria.age_from}`}>Age {renderAge()}</li>
        <li key={`injuryLevel${criteria.injury_level_from}`}>
          Level {criteria.injury_level_from}-{criteria.injury_level_to}
        </li>
        AIS {formatAis()}
        <li key={`injuryTime${criteria.time_since_injury_from}`}>
          {renderInjuryTime()}
        </li>
        {criteria.inclusion_criteria?.map(incl => (
          <li key={incl}>{incl}</li>
        ))}
      </ul>
    </div>
  );
};

export default CriteriaRow;
