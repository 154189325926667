import styles from './TableData.module.scss';
import Pagination from 'components/Pagination/Pagination';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import classNames from 'classnames';

const DataTable = ({
  columns,
  onTableRowClick,
  dataFetcher,
  headerFormatter,
  rowFormatter,
  searchData,
  setMetaData,
  noSorting,
  savedFilters,
  handleSortByColumn,
  sort,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [tableRowsData, setTableRowsData] = useState([]);
  const [tableMetaData, setTableMetaData] = useState({
    data: {},
    links: {},
    meta: { last_page: 0 },
  });
  const tableInstance = useTable({
    columns,
    data: tableRowsData,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const handleSortIcon = column => {
    const prefixedColumn = `${column.columnPrefix}.${column.id}`;

    const stateSortDirection = sort.direction;
    const stateColumn = sort.column;

    const showSmallSortDescIcon =
      stateSortDirection === 'DESC' && prefixedColumn === stateColumn;
    const showSmallSortAscIcon =
      stateSortDirection === 'ASC' && prefixedColumn === stateColumn;

    let icon = IconComponents.SmallSortNone;

    if (showSmallSortDescIcon) {
      icon = IconComponents.SmallSortDesc;
    } else if (showSmallSortAscIcon) {
      icon = IconComponents.SmallSortAsc;
    }

    return (
      <IconWrapper Icon={icon} onClick={() => handleSortByColumn(column)} />
    );
  };

  useEffect(() => {
    dataFetcher(currentPage, sort?.column, sort?.direction).then(result => {
      const trials = result.data.map(({ trial, rawTrial }) => {
        return { ...trial, ...rawTrial };
      });
      setTableMetaData(result.meta);
      if (setMetaData) {
        setMetaData(result?.meta);
      }
      setTableRowsData(trials);
    });
  }, [currentPage, sort, searchData, savedFilters]);

  const lastPage = tableMetaData ? tableMetaData.last_page : 0;

  return (
    <>
      <div className={styles.tableWrapper}>
        <table {...getTableProps()} className={styles.table}>
          <thead className={styles.tableHead}>
            {headerGroups.map(headerGroup => {
              return (
                <tr
                  className={styles.tableHeadRow}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map(column => {
                    return (
                      <>
                        <th
                          className={classNames(
                            styles.tableHeadCell,
                            column.className,
                          )}
                        >
                          <div className={styles.sortWrapper}>
                            {headerFormatter
                              ? headerFormatter(column)
                              : column.render('Header')}
                            {!noSorting && handleSortIcon(column)}
                          </div>
                        </th>
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className={styles.tableBody}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  className={styles.tableBodyRow}
                  {...row.getRowProps()}
                  onClick={() => onTableRowClick(row)}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      onTableRowClick(row);
                    }
                  }}
                  tabIndex={0}
                >
                  {row.cells.map(cell => {
                    return (
                      <>
                        <Content
                          size="uiText50"
                          as="td"
                          theme="neutral"
                          {...cell.getCellProps([
                            {
                              className: `${cell.column.className} ${styles.tableBodyCell}`,
                            },
                          ])}
                        >
                          {rowFormatter
                            ? rowFormatter(cell)
                            : cell.render('Cell')}
                        </Content>
                      </>
                    );
                  })}
                  <td className={styles.actionColumn}>
                    <IconWrapper
                      Icon={IconComponents.ArrowForwardIcon}
                      alt="arrowRight"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        lastPage={lastPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};
export default DataTable;
