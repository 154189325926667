import styles from '@moonshineragency/ui/src/components/Pill/Pill.module.scss';

import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import React from 'react';
import classNames from 'classnames';
import { Button } from 'reakit/Button';

const Pill = ({
  theme = 'primary',
  children,
  variant = null,
  size = 'default',
  shape,
  className,
  isActive,
  onClick = null,
  ...other
}) => (
  <Button
    {...other}
    onClick={onClick}
    className={classNames(
      className,
      styles[theme],
      styles[shape],
      styles[variant],
      styles[size],
      styles.container,
      {
        [styles.active]: isActive,
      },
    )}
    tabIndex="-1"
  >
    {children}
    {onClick && (
      <IconWrapper
        Icon={IconComponents.SmallCrossXIcon}
        className={classNames(styles.iconWrapper)}
      />
    )}
  </Button>
);
export default Pill;
